<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

<!-- <div 
style="
     background: url(/media/user-bg.jpg) no-repeat; height:140px;
 
" > -->

<div>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-normal"> <router-link to="/">
        <img src="/media/logos/logo-light.png" alt="Logo" width="180"/>
      </router-link>
        
      </span>
   
        </v-list-item-title>
      </v-list-item-content>
      
    </v-list-item>
  


</div>

 

 
    <v-list 

    
      expand
      nav
    >






      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />




<div v-if="$acl.check('isAdmin')" :key="componentKey"  >

      <template v-for="(item3, i) in computedItem3s">
        <base-item-group
          v-if="item3.children"
          :key="`group-${i}`"
          :item="item3"
          
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
         
          :key="`item-${i}`"
          :item="item3"
        />
      </template>

</div>
  





      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
import gql from 'graphql-tag'
import { mapGetters,mapState, mapActions, mapMutations } from "vuex";

  export default {
    name: 'DashboardCoreDrawer',





    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },


 




    data: () => ({
      notifications: [],
    componentKey: 0,
      
 


item3s: [
        {
          icon: 'mdi-view-dashboard',
          title: 'Dashboard',
          to: '/',
        },
           {
          icon: 'mdi-chart-timeline-variant',
          title: 'Website Contact Form',
          to: '/admin/contactforms',
        },

         {
          icon: 'mdi-chart-timeline-variant',
          title: 'Seller List',
          to: '/admin/company-list',
        },

              {
          icon: 'mdi-chart-timeline-variant',
          title: 'Buyer List',
          to: '/admin/buyer-list',
        },
        {
          icon: 'mdi-chart-timeline-variant',
          title: 'View Buyer Applications',
          to: '/admin/buyers',
        },

         {
          icon: 'mdi-chart-timeline-variant',
          title: 'View Seller Applications',
          to: '/admin/sellers',
        },

                {
          icon: 'mdi-chart-timeline-variant',
          title: 'Link Companies',
          to: '/admin/addcompany',
        },
    
        {
          icon: 'mdi-chart-timeline-variant',
          title: 'New Seller/Supplier',
          to: '/admin/addbrand',
        },

        {
          icon: 'mdi-chart-timeline-variant',
          title: 'Add User',
          to: '/admin/adduser',
        },
        {
          icon: 'mdi-chart-timeline-variant',
          title: 'Edit Seller Profile',
          to: '/admin/edit-profile',
        },
          {
          icon: 'mdi-chart-timeline-variant',
          title: 'Edit Buyer Profile',
          to: '/admin/edit-bprofile',
        },
      ],




 
  





    }  
      
    
    ),








    computed: {

        ...mapGetters([
      "isAuthenticated",
      'currentUser'
    ]),
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },

       computedItem2s () {
        return this.item2s.map(this.mapItem2)
      },

         computedItem3s () {
        return this.item3s.map(this.mapItem3)
      },

   
    },
    apollo: {
      unreadChats: {
        query: gql`
          query UnreadChatsQuery($supplier_id: Int!) {
            unreadChats: unreadchats(supplier_id: $supplier_id) {
              id
              seller_id
              supplier_id
              message
              sender
              date
              is_seller
            }
          }
        `,
        variables() {
          return {
            supplier_id: parseInt(this.$store.state.auth.user.company_relation)
          }
        },
        client: 'chatClient',
        skip() {
          return true
        }
      },
      unreadSellerChats: {
        query: gql`
          query UnreadChatsQuery($seller_id: Int!) {
            unreadSellerChats: unreadsellerchats(seller_id: $seller_id) {
              id
              seller_id
              supplier_id
              message
              sender
              date
              is_seller
            }
          }
        `,
        variables() {
          return {
            seller_id: parseInt(this.$store.state.auth.user.company_relation)
          }
        },
        client: 'chatClient',
        skip() {
          return true
        }
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },
    mounted() {
      let self = this;
      const messageSentQuery = gql`subscription {
          messageSent {
            id
            seller_id
            supplier_id
            message
            sender
            date
            is_seller
          }
        }
      `;
      const messageSentObserver = this.$apollo.subscribe({
        query: messageSentQuery,
        client: 'chatClient',
      });
      messageSentObserver.subscribe({
        next (data) {
          self.updateUnreadChats();
        },
        error (error) {
          console.error(error)
        },
      })

      const updateReadQuery = gql`subscription {
          updateReadMsg {
            seller_id
            supplier_id
          }
        }
      `;
      const updateReadObserver = this.$apollo.subscribe({
        query: updateReadQuery,
        client: 'chatClient',
      });

      updateReadObserver.subscribe({
        next (data) {
          self.updateUnreadChats();
        },
        error (error) {
          console.error(error)
        },
      })

      setTimeout(() => {
        this.updateUnreadChats();
      }, 1000);
    },

    methods: {


       ...mapActions([
      'getUserInfo'
    ]),

      async updateUnreadChats() {
        if(this.$acl.check('isSupplier')) {
          this.$apollo.queries.unreadChats.skip = false;
          await this.$apollo.queries.unreadChats.refetch();
          this.notifications = this.unreadChats;
        } else if(this.$acl.check('isSeller')) {
          this.$apollo.queries.unreadSellerChats.skip = false;
          let res = await this.$apollo.queries.unreadSellerChats.refetch();
          this.notifications = this.unreadSellerChats;
        }
      },

      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
         
        }
      },

 mapItem2 (item2) {
        return {
          ...item2,
          children: item2.children ? item2.children.map(this.mapItem2) : undefined,
          
        }
      },
  mapItem3 (item3) {
        return {
          ...item3,
          children: item3.children ? item3.children.map(this.mapItem3) : undefined,
          
        }
      },


  mounted() {
    setTimeout(() => {
      this.componentKey += 1;
    }, 200);
  },


    },
  }
</script>







<style lang="sass">

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
